/* #007bff  */

/*  */
.Decorations_Container {
  /* padding: 50px; */
  /* background-color: #f4f6f9; */
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 50px;
  flex: 1;
}
.Blue_Color {
  color: #007bff;
  font-weight: 700;
}

.Decorations_Head_Container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px;
  border-bottom: 1px solid #dee2e6;
  margin-top: -50px;
}

.Decorations_Left {
  display: flex;
  gap: 32px;
  align-items: center;
}

.Decorations_Add_Decorations_Button {
  display: flex;
  align-items: center;
  padding: 12px;
  text-decoration: none;
  gap: 8px;
  border: none;
  font-size: 18px;
  background-color: #57b846;

  cursor: pointer;
  color: #fff;
}
.Decorations_Add_Decorations_Button a,
.Decorations_Add_Decorations_Button a:visited,
.Decorations_Add_Decorations_Button a:active {
  text-decoration: none;
  color: #fff;
}
.Decorations_Form_Container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  padding: 0 50px;
  gap: 24px;
}
.Decorations_Form_Main {
  /* padding: 10px; */
  border-radius: 5px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 8px;
}
.Decorations_Form_Head,
.Decorations_Form_Head2 {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  align-items: center;
  height: 12vh;
}
.Decorations_Form_Head2 {
  background-color: #e5e5e5;
  padding-left: 30px;
}
.Decorations_Head_Box {
  max-width: 10vw;
  margin-left: -8px;
}

.Decorations_Head_Box {
  width: 15vw;
  max-width: 15vw;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.Decorations_Head_Box:first-child {
  padding-left: 15px;
  justify-content: start;
  width: 1vw;
}

.Decorations_Form_Head2:nth-child(odd) {
  background-color: #fff;
}

.Decorations_Form_Head2:nth-child(even) {
  background-color: #e5e5e5;
}

.Decorations_Images_Preview {
  width: 8vw;
  height: 10vh;
}

.Button_Page {
  background-color: transparent;
  padding: 10px;
  border: none;
  border: 1px solid #dee2e6;
  cursor: pointer;
}

.Button_Active {
  background-color: #007bff;
  color: #fff;
}
.Button_Active_Pages {
  background-color: #fff;
  color: #007bff;
}
.Button_NotActive_Pages {
  background-color: #fff;
  color: #000;
}
.Button_NotActive {
  background-color: #fff;
  color: #007bff;
}
.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
}
.Decorations_Link,
.Decorations_Link:visited,
.Decorations_Link:active {
  text-decoration: none;
  color: #007bff;
}
.Decorations_Filter_Container {
  display: flex;
  gap: 24px;
}
.Decorations_Filter_Select {
  width: 12vw;
}
.Decorations_Filter_Input {
  width: 12vw;
}
/*  */
/*  */
/* here for pop up till data arrive from api */

.loading-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 1000;
}

.loading-circle {
  width: 100px; /* Increase the size of the circle */
  height: 100px; /* Increase the size of the circle */
  border-radius: 50%;
  border: 1px solid #333;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; /* Add relative positioning */
}

.loading-semicircle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid transparent;
  border-top: 2px solid #333;
  position: absolute; /* Positioned absolutely to the circle */
  top: 0;
  left: 0;
  animation: rotateSemicircle 2s linear infinite;
}

.loading-text {
  font-size: 16px; /* Adjust font size as needed */
  position: absolute; /* Positioned absolutely to the circle */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the text */
}

.loading-dots::after {
  content: "";
  animation: dots 1.5s steps(5, end) infinite;
}

@keyframes rotateSemicircle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dots {
  0%,
  20% {
    content: ".";
  }
  40% {
    content: "..";
  }
  60% {
    content: "...";
  }
  80%,
  100% {
    content: "";
  }
}

/*  */
/*  */
/*  */
/* menu burger */
/* App.css */
.bm-item {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
  color: #fff;
  transition: color 0.2s;
}

.bm-item:hover {
  color: #d1d1d1;
}

.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 30px;
  left: 26px;
  top: 16px;
}

.bm-burger-bars {
  background: #373a47;
  background: #28a745;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: #bdc3c7;
  /* background: #28a745; */
}

.bm-menu {
  background: #373a47;
  /* background: #28a745; */
  padding: 2.5em 1.5em 0;
  font-size: 1.35em;
  font-weight: bold;
}
.menu_item_room {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
}

.bm-morph-shape {
  fill: #373a47;
}

.bm-item-list {
  color: #b8b7ad;
}
.Decorations_Delete_Button {
  padding: 12px;
  font-size: 16px;
  color: #fff;
  background-color: red;
  border: none;
  cursor: pointer;
}
.Decorations_Add_Decorations_Button,
.Decorations_Delete_Button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  /* min-width: 160px; */
  height: 50px;
  border-radius: 25px;

  font-family: Poppins-Regular;
  font-size: 16px;
  color: #fff;
  line-height: 1.2;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.Decorations_Add_Decorations_Button:hover,
.Decorations_Delete_Button:hover {
  background-color: #333333;
}
.Decorations_Header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 30px;
  font-size: 18px;
}

.Decorations_Add_Decorations_Button_DownLoad {
  display: flex;
  align-items: center;
  padding: 12px;
  text-decoration: none;
  gap: 8px;
  border: none;
  font-size: 18px;
  background-color: #57b846;

  cursor: pointer;
  color: #fff;
}
.Decorations_Add_Decorations_Button_DownLoad a,
.Decorations_Add_Decorations_Button_DownLoad a:visited,
.Decorations_Add_Decorations_Button_DownLoad a:active {
  text-decoration: none;
  color: #fff;
}
.Decorations_Add_Decorations_Button_DownLoad:hover {
  background-color: #333333;
}
.Decorations_Add_Decorations_Button_DownLoad {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  border-radius: 25px;
  min-width: 120px;
  height: 50px;
  font-family: Poppins-Regular;
  font-size: 16px;
  color: #fff;
  line-height: 1.2;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}
.Decorations_Right {
  display: flex;
  gap: 12px;
}
.IE_Icon {
  width: 25px;
}

/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
