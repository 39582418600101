* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.Container {
  display: flex;
  flex-direction: column;
  padding: 0px 1.5vw;
  gap: 32px;
  flex: 1;
}
.Add_Room {
  font-size: 1.5rem;
  font-family: inherit;
  font-weight: 700;
  line-height: 1.2;
  color: #343a40;
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
}
.Field_Container {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: #fff;
  height: 60vh;
  border-radius: 5px;
  padding: 1.5vw;
  display: flex;
  flex-direction: column;
  gap: 1.5vw;
}
.Form2_Contain_L1 {
  display: flex;
  background-color: transparent;
  gap: 1.6vw;
}
.Input_Box,
.Form_Box {
  display: flex;
  align-items: center;
  width: fit-content;
  background: transparent;
  gap: 0.8vw;
}
h3 {
  font-size: 1rem;
  width: 10vw;
  background: transparent;
}
.Input,
.InputForm2 {
  padding: 0.575rem 0.95rem;
  border-radius: 2px;
  border: 1px solid #ced4da;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  width: 30vw;
}
.InputForm2 {
  width: 13vw;
}
.Select_Box {
  line-height: 1.5;
  height: 5vh;
  width: 14vw;
  color: black;
}
.MultiSelect_Box {
  min-width: 15vw;
  width: auto;
  max-width: 55vw;
}

.N_Button,
.B_Button {
  position: absolute;
  width: 5vw;
  top: 70%;
  right: 7%;
  cursor: pointer;
  background-color: #17a2b8;
  border-color: #17a2b8;
  color: #fff;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 0.75rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}
.B_Button {
  left: 7%;
  right: 0;
}
.SubMit {
  color: wheat;
}
.textarea {
  height: 25vh;
  min-height: 25vh;
  max-height: 25vh;
  width: 30vw;
  min-width: 30vw;
  max-width: 30vw;
  border: #17a2b8 1px solid;
  color: #000;
  font-size: 12px;
  padding: 10px;
}
