.Form_Images_Box_Edit {
  display: flex;
  align-items: center;
  width: fit-content;
  background: transparent;
  height: 5vh;
  padding: 0rem;
  padding-left: 28px;
  width: 27vw;
  color: #007bff;
}
.Form_Images_Box_Edit a,
.Form_Images_Box_Edit a:visited,
.Form_Images_Box_Edit a:active {
  color: #007bff;
}
.Container_SecondPage {
  position: relative;
}
.Update_Room_Button {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 50px;
  top: 173px;
  padding: 12px;
  text-decoration: none;
  gap: 8px;
  border: none;
  font-size: 18px;
  background-color: #28a745;
  cursor: pointer;
  color: #fff;
  width: 10vw;
}
.Update_Room_Button2 {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 50px;
  bottom: -50px;
  padding: 12px;
  text-decoration: none;
  gap: 8px;
  border: none;
  font-size: 18px;
  background-color: #28a745;
  cursor: pointer;
  color: #fff;
  width: 10vw;
  display: none;
}
.Update_Room_Button,
.Update_Room_Button2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  min-width: 160px;
  height: 50px;
  background-color: #57b846;
  border-radius: 25px;

  font-family: Poppins-Regular;
  font-size: 16px;
  color: #fff;
  line-height: 1.2;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.Update_Room_Button:hover,
.Update_Room_Button2:hover {
  background-color: #333333;
}
.Update_Room_Button2 {
  display: none;
}
.EditRoomS {
  padding-bottom: 20px;
}
.Form_Images_Box_Edit {
  display: flex;
  align-items: center;
  width: fit-content;
  background: transparent;
  height: 5vh;
  padding: 0rem;
  padding-left: 28px;
  width: 30vw;
}
.Images_Head_Container_Edit {
  font-size: 16px;
  font-weight: 700;
  color: #64748b;
  padding: 0.75rem;
  padding-left: 30px;
  display: flex;
  /* justify-content: space-between; */
  gap: 26vw;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}
.Form_Images_Box_Preivew_Edit {
  padding: 0;
  margin: 0;
  width: 35vw;
}
