.GoEdit_Main {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 40vh;
  padding: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  background-color: #fff;
  border-radius: 10px;
  width: 60vw;
}
.GoEdit_Input {
  border: 1px soild black;
  height: 5vh;
  box-shadow: 0 3px 8px #0000003d;
}
.GoEdit_Container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 100px;
  height: 100vh;
}
.input-container {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.styled-input {
  width: 100%;
  padding: 10px 15px;
  font-size: 16px;
  border: 2px solid #ddd;
  border-radius: 5px;
  transition: border-color 1155.3s ease;
  outline: none;
}
.styled-input:focus {
  border-color: #007bff;
  transition: border-color 1155.3s ease-in;
}
.styled-input::placeholder {
  color: #aaa;
}
.GoEdit_Room_Button {
  display: flex;
  align-items: center;
  justify-content: center;
  right: 50px;
  top: 173px;
  padding: 12px;
  text-decoration: none;
  gap: 8px;
  border: none;
  font-size: 18px;
  background-color: #28a745;
  cursor: pointer;
  color: #fff;
  width: 10vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  min-width: 160px;
  height: 50px;
  background-color: #57b846;
  border-radius: 25px;
  font-family: Poppins-Regular;
  font-size: 16px;
  color: #fff;
  line-height: 1.2;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}
.GoEdit_Room_Button:hover {
  background-color: #333333;
}
.GoEdit_Box {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.GoEdit_Box h3 {
  margin-left: 3px;
  width: 100%;
}
.GoEdit_Box {
  /* margin-bottom: 20px; */
}

.styled-input {
  width: 15.5vw;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.styled-input:focus {
  border-color: #66afe9;
  outline: none;
}
.GoEdit_Select_Box {
  color: black;
  width: 15.5vw;
}
.GoEdit_UserLogged {
  display: flex;
  padding: 30px 30px;
  justify-content: flex-end;
}
